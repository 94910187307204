import _tinyInflate from "tiny-inflate";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Generated by CoffeeScript 1.7.1
var UnicodeTrie, inflate;
inflate = _tinyInflate;

UnicodeTrie = function () {
  var DATA_BLOCK_LENGTH, DATA_GRANULARITY, DATA_MASK, INDEX_1_OFFSET, INDEX_2_BLOCK_LENGTH, INDEX_2_BMP_LENGTH, INDEX_2_MASK, INDEX_SHIFT, LSCP_INDEX_2_LENGTH, LSCP_INDEX_2_OFFSET, OMITTED_BMP_INDEX_1_LENGTH, SHIFT_1, SHIFT_1_2, SHIFT_2, UTF8_2B_INDEX_2_LENGTH, UTF8_2B_INDEX_2_OFFSET;
  SHIFT_1 = 6 + 5;
  SHIFT_2 = 5;
  SHIFT_1_2 = SHIFT_1 - SHIFT_2;
  OMITTED_BMP_INDEX_1_LENGTH = 65536 >> SHIFT_1;
  INDEX_2_BLOCK_LENGTH = 1 << SHIFT_1_2;
  INDEX_2_MASK = INDEX_2_BLOCK_LENGTH - 1;
  INDEX_SHIFT = 2;
  DATA_BLOCK_LENGTH = 1 << SHIFT_2;
  DATA_MASK = DATA_BLOCK_LENGTH - 1;
  LSCP_INDEX_2_OFFSET = 65536 >> SHIFT_2;
  LSCP_INDEX_2_LENGTH = 1024 >> SHIFT_2;
  INDEX_2_BMP_LENGTH = LSCP_INDEX_2_OFFSET + LSCP_INDEX_2_LENGTH;
  UTF8_2B_INDEX_2_OFFSET = INDEX_2_BMP_LENGTH;
  UTF8_2B_INDEX_2_LENGTH = 2048 >> 6;
  INDEX_1_OFFSET = UTF8_2B_INDEX_2_OFFSET + UTF8_2B_INDEX_2_LENGTH;
  DATA_GRANULARITY = 1 << INDEX_SHIFT;

  function UnicodeTrie(data) {
    var isBuffer, uncompressedLength, view;
    isBuffer = typeof data.readUInt32BE === "function" && typeof data.slice === "function";

    if (isBuffer || data instanceof Uint8Array) {
      if (isBuffer) {
        (this || _global).highStart = data.readUInt32BE(0);
        (this || _global).errorValue = data.readUInt32BE(4);
        uncompressedLength = data.readUInt32BE(8);
        data = data.slice(12);
      } else {
        view = new DataView(data.buffer);
        (this || _global).highStart = view.getUint32(0);
        (this || _global).errorValue = view.getUint32(4);
        uncompressedLength = view.getUint32(8);
        data = data.subarray(12);
      }

      data = inflate(data, new Uint8Array(uncompressedLength));
      data = inflate(data, new Uint8Array(uncompressedLength));
      (this || _global).data = new Uint32Array(data.buffer);
    } else {
      (this || _global).data = data.data, (this || _global).highStart = data.highStart, (this || _global).errorValue = data.errorValue;
    }
  }

  UnicodeTrie.prototype.get = function (codePoint) {
    var index;

    if (codePoint < 0 || codePoint > 1114111) {
      return (this || _global).errorValue;
    }

    if (codePoint < 55296 || codePoint > 56319 && codePoint <= 65535) {
      index = ((this || _global).data[codePoint >> SHIFT_2] << INDEX_SHIFT) + (codePoint & DATA_MASK);
      return (this || _global).data[index];
    }

    if (codePoint <= 65535) {
      index = ((this || _global).data[LSCP_INDEX_2_OFFSET + (codePoint - 55296 >> SHIFT_2)] << INDEX_SHIFT) + (codePoint & DATA_MASK);
      return (this || _global).data[index];
    }

    if (codePoint < (this || _global).highStart) {
      index = (this || _global).data[INDEX_1_OFFSET - OMITTED_BMP_INDEX_1_LENGTH + (codePoint >> SHIFT_1)];
      index = (this || _global).data[index + (codePoint >> SHIFT_2 & INDEX_2_MASK)];
      index = (index << INDEX_SHIFT) + (codePoint & DATA_MASK);
      return (this || _global).data[index];
    }

    return (this || _global).data[(this || _global).data.length - DATA_GRANULARITY];
  };

  return UnicodeTrie;
}();

exports = UnicodeTrie;
export default exports;